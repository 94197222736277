import React, { useEffect, useMemo, useState } from "react";
import queryString from "query-string";
import { useQuery, gql, useLazyQuery, ApolloError, useMutation } from "@apollo/client";
import {
  Card,
  CardContent,
  IconButton,
  LinearProgress,
  Typography,
  Tooltip,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Overview from "./Overview";
import { startOfMonth, addMonths } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from "../../../components/TextField";
import { format } from "date-fns-tz";
import { useNotifications } from "../../../components/Notification";
import Money from "./Money";
import EmailPreviewModal from './EmailPreviewModal'
const lastMonth = startOfMonth(addMonths(new Date(), -1));

const DOWNLOAD_TYPE = "download";
const SENT_MAIL_STATUS = {
  FAIL: 3,
  SUCCESS: 2
}
export default function VenueCosts() {
  const navigate = useNavigate();
  const location = useLocation();
  const [yearMonth, setYearMonth] = useState<Date | null>(lastMonth);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [reportVenues, setReportVenues] = useState([]);
  const [companyList, setcompanyList] = useState([]);
  const [modalTableList, setModalTableList] = useState([]);
  const rowsPerPage = 10;
  const parsedQueryString = queryString.parse(location.search);
  const { showNotification } = useNotifications();
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [currentEmailIndex, setCurrentEmailIndex] = useState(0);
  const [page, setPage] = React.useState(
    parsedQueryString.page ? parseInt(parsedQueryString.page.toString()) - 1 : 0
  );
  const [search, setSearch] = React.useState(
    parsedQueryString.search ? parsedQueryString.search.toString() : null
  );
  const [fetchDataModalLoading, setFetchDataModalLoading] = useState(false);
  const [
    fetchFileZipPdfExport,
    { loading: downloadLoading, data: downloadData, error: downloadError },
  ] = useLazyQuery(FETCH_FILE_ZIP_PDF_EXPORT, { fetchPolicy: "network-only" });
  const [saveCompanyMutation] = useMutation(SAVE_COMPANY_MUTATION);

  const [sendEmail, { data: dataSendMail, loading: loadingSendMail, error: errorSendMail }] = useLazyQuery(SEND_EMAIL, {
    onError: (err: ApolloError) => {
      showNotification({
        message: "Send emails error",
        severity: "error",
      });
      console.error("GraphQL error:", err.message);
    },
    onCompleted: (data: any) => {
      if (data.sendVenueNoticeEmails.success) {
        showNotification({
          message: "Send emails Success",
          severity: "success",
        });
      } else {
        showNotification({
          message: "Send emails error",
          severity: "error",
        });
      }
    },
  });

  const [previewEmail, { data: dataPreviewEmail, loading: loadingPreviewEmail, error: errorPreviewEmail }] = useLazyQuery(PREVIEW_EMAIL, {
    onError: (err: ApolloError) => {
      showNotification({
        message: "Preview emails error",
        severity: "error",
      });
      console.error("GraphQL error:", err.message);
    },
    onCompleted: (data: any) => {
      setOpenPreviewModal(true);
    },
  });

  useEffect(() => {
    const urlParams = {
      page: page + 1,
      search: search,
    };
    navigate({
      search: queryString.stringify(urlParams, {
        skipNull: true,
        skipEmptyString: true,
        sort: false,
      }),
    });
  }, [page, search, navigate]);

  const resetPagination = () => {
    setPage(0);
    setTotalRows(0);
  };

  const from = yearMonth ? format(yearMonth, "yyyy-MM-dd") : null;
  const until = yearMonth
    ? format(startOfMonth(addMonths(yearMonth, 1)), "yyyy-MM-dd")
    : null;

  const { loading, error, data } = useQuery(GET_VENUE_RESERVATIONS, {
    variables: {
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      search,
      from,
      until,
      hasPaidReservations: {
        from,
        until,
      },
    },
  });

  // Fetch the data but initially prevent it from running with `skip: true`
  const {
    data: reportData,
    loading: reportLoading,
    error: reportError,
    refetch: refetchReport,
  } = useQuery(REPORT_VENUE_QUERY, {
    variables: {
      from: yearMonth ? format(yearMonth, "yyyy-MM-dd'T'00:00:00'Z'") : null,
      search,
    },
    skip: true, // Prevent query from running automatically
  });

  const {
    data: companyVenueData,
    loading: companyVenueLoading,
    error: companyVenueError,
    refetch: refetchCompanyVenue,
  } = useQuery(FETCH_COMPANY_VENUE, {
    variables: {
      from: yearMonth ? format(yearMonth, "yyyy-MM-dd'T'00:00:00'Z'") : null,
      search,
    },
    skip: true, // Prevent query from running automatically
  });

  const handlePreviousEmail = () => {
    setCurrentEmailIndex((prevIndex: number) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  const rows = useMemo(
    () =>
      [...(data?.venues.records ?? [])].map((venue) => ({
        ...venue,
        reservations: venue.activities.flatMap((activity: any) =>
          activity.reservations.map((reservation: any) => ({
            ...reservation,
            status:
              reservation.booking.status === "CANCELLED"
                ? "CANCELLED"
                : reservation.status,
            activityName: activity.name,
            planName: activity.plan.name,
            priceTypes: activity.priceTypes,
          }))
        ),
      })),
    [data]
  );

  const [totalRows, setTotalRows] = useState(0);
  const [checked, setChecked] = useState<boolean[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [zipNameFile, setZipNameFile] = useState<string>("");
  const checkedEmails = useMemo(() => {
    return modalTableList.filter((_, index: number) => checked[index]);
  }, [checked]);



  const handleNextEmail = () => {
    setCurrentEmailIndex((prevIndex: number) =>
      prevIndex < checked.length - 1 ? prevIndex + 1 : prevIndex
    );
  };
  useEffect(() => {
    setChecked(companyList.map(() => false));
  }, [setcompanyList]);

  useEffect(() => {
    if (modalType === DOWNLOAD_TYPE) {
      setChecked(reportVenues.map(() => false));
    } else {
      setChecked(companyList.map(() => false));
    }
  }, [reportVenues, companyList]);

  useEffect(() => {
    if (data === undefined) return;
    setTotalRows(data.venues.total);
  }, [data]);

  useEffect(() => {
    if (downloadData) {
      if (downloadData.fetchFileZipPdfExport.filezip) {
        const zipData = downloadData.fetchFileZipPdfExport.filezip;
        if (!zipData) return;

        const data = Uint8Array.from(atob(zipData), (c) => c.charCodeAt(0));
        const blob = new Blob([data], {
          type: "application/zip",
        });

        exportBlob(blob, `${zipNameFile}.zip`);
      } else if (downloadData.fetchFileZipPdfExport.error) {
        console.error(
          "Error fetching report: ",
          downloadData.fetchFileZipPdfExport.error
        );
        showNotification({
          message: downloadData.fetchFileZipPdfExport.error,
          severity: "error",
        });
      }
    }
  }, [downloadData]);

  const totalPages = Math.ceil(totalRows / rowsPerPage);

  if (error) return <>Error! {error.message}</>;

  // Modal Content
  const handleModalOpen = async (type: string) => {
    setModalType(type);
    setOpenModal(true);
    setFetchDataModalLoading(true)
    if (type === DOWNLOAD_TYPE) {
      // Manually trigger the report query
      const { data: fetchedReportData } = await refetchReport();

      // Fill data into reportVenues
      const fetchedReportVenues = fetchedReportData.getReportVenues.records.map(
        (record: any) => ({
          id: record.venue.id,
          venueName: record.venue.name,
          totalAmount: record.totalAmount,
          exportedAt: record.exportTime,
          url: record.url, // URL for PDF download
        })
      );
      setReportVenues(fetchedReportVenues);
      setModalTableList(fetchedReportVenues);
      setFetchDataModalLoading(false);
    } else {
      // Manually trigger the report query
      const { data: fetchedReportData } = await refetchCompanyVenue();
      // Fill data into reportVenues
      const fetchedReportVenues = fetchedReportData.companiesToSendMail.records.map(
        (record: any) => ({
          id: record.company_id,
          venueName: record.company_name,
          totalAmount: record.total_amount,
          exportedAt: record.send_time,
          mailStatus: record.mail_status,
          manualResponse: record.manual_response
          // url: record.url, // URL for PDF download
        })
      );
      setcompanyList(fetchedReportVenues);
      setModalTableList(fetchedReportVenues);
      setFetchDataModalLoading(false);
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setAllChecked(false);
    setChecked(reportVenues.map(() => false));
    setReportVenues([]);
    setcompanyList([]);
    setModalTableList([]);
  };

  const handleToggleAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setAllChecked(isChecked);
    setChecked(modalTableList.map(() => isChecked));
    if (modalType !== DOWNLOAD_TYPE) {
      setChecked(modalTableList.map((item: any) => {
        if (`${item.mailStatus}` === `${SENT_MAIL_STATUS.SUCCESS}`) {
          return false;
        } else {
          return isChecked;
        }
      }));
    }
  };

  const handleToggleCheckbox = (index: number) => {
    const updatedChecked = [...checked];
    updatedChecked[index] = !updatedChecked[index];
    setChecked(updatedChecked);

    if (updatedChecked.every((isChecked) => isChecked)) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  };

  const handleDownload = (type: number) => {

    const selectedVenues = modalTableList.filter(
      (_, index: number) => checked[index]
    );
    const selectedVenueIds = selectedVenues.map((venue: any) => venue.id);
    // const selectedVenueIds = ["01baf56b-714e-482c-9324-3700d9c6e1fe"];
    if (selectedVenueIds.length === 0) {
      showNotification({ message: "No record checked", severity: "error" });
      return;
    }
    // Ví dụ về month, bạn cần thay đổi tùy theo ngữ cảnh
    const month = yearMonth
      ? format(yearMonth, "yyyy-MM-dd'T'00:00:00'Z'")
      : null;

    let yymm = "";
    if (yearMonth) {
      const year = yearMonth.getFullYear().toString().slice(-2);
      const month = (yearMonth.getMonth() + 1).toString().padStart(2, '0');
      yymm = `_${year}${month}`;
    }
    if (type === 1) {
      setZipNameFile(`【Annyお祝い体験】ご請求書${yymm}`)
    } else if (type === 2) {
      setZipNameFile(`【Annyお祝い体験】お支払通知書${yymm}`)
    } else {
      setZipNameFile(`【Annyお祝い体験】アイテムセット${yymm}`)
    }
    try {
      fetchFileZipPdfExport({
        variables: {
          venueIds: selectedVenueIds,
          month,
          type,
        },
      });
      handleModalClose();
    } catch (error) {
      console.error("Error during invoice download: ", error);
      showNotification({
        message: "Error during invoice download",
        severity: "error",
      });
    }
  };

  const handleClosePreviewModal = () => {
    setCurrentEmailIndex(0);
    setOpenPreviewModal(false);
  };

  const handleSendEmail = () => {
    if (checkedEmails.length === 0) {
      showNotification({
        message: "No company selected.",
        severity: "error",
      });
      return;
    }
    const companyIds = checkedEmails.map((company: any) => company.id)
    sendEmail({ variables: { companyIds, month: yearMonth ? format(yearMonth, "yyyy-MM-dd") : null } });
    handleModalClose();
  }

  const handleOpenPreview = (id: string) => {
    if (id) {
      previewEmail({ variables: { companyIds: [id], month: yearMonth ? format(yearMonth, "yyyy-MM-dd") : null } });
    }
  };

  const handleCheckboxChange = async (event: any, id: string) => {
    const newCheckedStatus = event.target.checked;
    const input = {
      id: id,
      manualResponse: newCheckedStatus ? 1 : 0,
    };

    try {
      const { data } = await saveCompanyMutation({
        variables: { input },
      });
      if (data.updateManualResponseStatus.success) {
        console.log('Manual response updated successfully');
      } else {
        console.error('Error:', data.updateManualResponseStatus.error);
      }
    } catch (error) {
      console.error('Mutation failed:', error);
    }
  };

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <div style={{ flexShrink: 0 }}>
              <DatePicker
                label="Month and Year"
                value={yearMonth}
                onChange={(event) => {
                  setYearMonth(event);
                  resetPagination();
                }}
                views={["year", "month"]}
                openTo="month"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {!!totalRows && (
                <Typography style={{ whiteSpace: "nowrap" }}>
                  {page * rowsPerPage + 1} -{" "}
                  {Math.min(totalRows, (page + 1) * rowsPerPage + 1)} of{" "}
                  {totalRows}
                </Typography>
              )}
              <div style={{ display: "flex" }}>
                <IconButton
                  disabled={page === 0 || totalRows === 0}
                  onClick={() => setPage((page) => Math.max(0, page - 1))}
                >
                  <NavigateBeforeIcon />
                </IconButton>
                <IconButton
                  disabled={page === totalPages - 1 || totalRows === 0}
                  onClick={() =>
                    setPage((page) => Math.min(totalPages - 1, page + 1))
                  }
                >
                  <NavigateNextIcon />
                </IconButton>
              </div>
            </div>
            <TextField
              label="Search"
              style={{ flexShrink: 1 }}
              value={search ?? ""}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearch(event.target.value);
                resetPagination();
              }}
            />
          </div>
        </CardContent>
        <Box
          display="flex"
          sx={{
            gap: "10px",
            justifyContent: "end",
            marginRight: "20px",
            marginBottom: "15px",
          }}
        >
          <Tooltip title={"Download PDF"}>
            <span>
              {downloadLoading ? (
                <Button
                  sx={{
                    backgroundColor: "#2196F3", // Blue color for the button
                    color: "#FFFFFF",
                    pointerEvents: "none",
                  }}
                  variant="outlined"
                  onClick={() => { }}
                >
                  EXPORT IN PROGRESS
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => handleModalOpen(DOWNLOAD_TYPE)}
                >
                  Download PDF
                </Button>
              )}
            </span>
          </Tooltip>
          {/* <Tooltip title={"SEND EMAIL"}>
            <span>
              {loadingSendMail ? <Button
                sx={{
                  backgroundColor: "#2196F3", // Blue color for the button
                  color: "#FFFFFF",
                  pointerEvents: "none",
                }}
                variant="outlined"
                onClick={() => { }}
              >
                SEND EMAIL IN PROGRESS
              </Button> : <Button
                variant="outlined"
                onClick={() => handleModalOpen("sendEmail")}
              >
                SEND EMAIL
              </Button>}
            </span>
          </Tooltip> */}
        </Box>
      </Card>

      {/* Modal Dialog */}
      <Dialog maxWidth={"lg"} open={openModal} onClose={handleModalClose}>
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <DialogTitle sx={{ fontWeight: "bold" }}>{`${modalType === DOWNLOAD_TYPE ? "Venue List" : "Company List"
              }`}</DialogTitle>
            <DialogActions>
              {modalType === DOWNLOAD_TYPE ? (
                <>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleDownload(3);
                    }}
                  >
                    DOWNLOAD ITEM SET
                  </Button>
                  <Button
                    variant="outlined"

                    onClick={() => {
                      handleDownload(2);
                    }}
                  >
                    DOWNLOAD PAYMENT NOTICE
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleDownload(1);
                    }}
                  >
                    DOWNLOAD INVOICE
                  </Button>
                </>
              ) : (
                <>
                  {/* <Button
                    variant="outlined"
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      borderColor: "black",
                      borderWidth: "2px",
                      "&:hover": {
                        borderColor: "black",
                        borderWidth: "2px",
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                      },
                    }}
                    onClick={handleOpenPreview}
                  >
                    PREVIEW
                  </Button> */}
                  <Button
                    variant="outlined"
                    onClick={handleSendEmail}
                  >
                    SEND EMAIL
                  </Button>
                </>
              )}
            </DialogActions>
          </Box>

          {!fetchDataModalLoading ? (
            <DialogContent>
              <TableContainer>
                <Table sx={{ borderCollapse: "collapse" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ border: "none" }}>
                        <Checkbox
                          checked={allChecked}
                          onChange={handleToggleAll}
                        />
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>{`${modalType === DOWNLOAD_TYPE
                        ? "Venue Name"
                        : "Company Name"
                        }`}</TableCell>
                      {modalType === DOWNLOAD_TYPE ? (
                        ""
                      ) : (
                        <TableCell sx={{ border: "none" }}></TableCell>
                      )}
                      <TableCell sx={{ border: "none" }}>
                        Total Amount
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>{`${modalType === DOWNLOAD_TYPE
                        ? "Latest Exported at"
                        : "Latest Sent at"
                        }`}</TableCell>
                      {modalType !== DOWNLOAD_TYPE &&
                        <TableCell sx={{ border: "none" }}>
                        </TableCell>
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {modalTableList?.length > 0 ? modalTableList.map((venue: any, index: number) => {
                      const isDisabled = modalType !== DOWNLOAD_TYPE && `${venue.mailStatus}` === `${SENT_MAIL_STATUS.SUCCESS}`;
                      const isError = modalType !== DOWNLOAD_TYPE && `${venue.mailStatus}` === `${SENT_MAIL_STATUS.FAIL}`;
                      return (
                        <TableRow key={index} sx={{
                          opacity: isDisabled ? 0.5 : 1,
                          pointerEvents: isDisabled ? 'none' : 'auto',
                          cursor: isDisabled ? 'not-allowed' : 'pointer',
                          '& > td': isError ? { color: 'red' } : {},
                        }} >
                          <TableCell sx={{ border: "none", paddingY: "4px" }}>
                            <Checkbox
                              disabled={isDisabled}
                              checked={checked[index] ? true : false}
                              onChange={() => handleToggleCheckbox(index)}
                            />
                          </TableCell>
                          <TableCell
                            sx={{
                              whiteSpace: "nowrap",
                              border: "none",
                              paddingY: "4px",
                              maxWidth: "400px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {venue.venueName}
                          </TableCell>
                          {modalType === DOWNLOAD_TYPE ? (
                            ""
                          ) : (
                            <TableCell
                              sx={{
                                whiteSpace: "nowrap",
                                border: "none",
                                paddingY: "4px",
                              }}
                            >
                              <FormControlLabel
                                control={<Checkbox
                                  defaultChecked={venue?.manualResponse === 1 ? true : false}
                                  onChange={(e: any) => {
                                    handleCheckboxChange(e, venue.id)
                                  }}
                                />}
                                label="手動対応"
                              />
                            </TableCell>
                          )}
                          <TableCell sx={{ border: "none", paddingY: "4px" }}>
                            <Money
                              amount={venue.totalAmount}
                              currencyCode="JPY"
                            />
                          </TableCell>
                          <TableCell sx={{ border: "none", paddingY: "4px" }}>
                            {venue.exportedAt}
                          </TableCell>
                          {modalType !== DOWNLOAD_TYPE && <TableCell sx={{ border: "none", paddingY: "4px" }}>
                            <Button
                              variant="outlined"
                              onClick={() => { handleOpenPreview(venue.id) }}
                            >
                              PREVIEW
                            </Button>
                          </TableCell>}

                        </TableRow>
                      )
                    }
                    ) : <TableRow>
                      <TableCell colSpan={5} align="center"
                      >No record</TableCell>
                    </TableRow>}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
          ) : (
            <Box
              sx={{ height: "200px" }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <CircularProgress />
            </Box>
          )}
        </>
      </Dialog>
      <EmailPreviewModal open={openPreviewModal} onClose={handleClosePreviewModal} dataPreviewEmail={dataPreviewEmail} />
      {loading ? (
        <LinearProgress />
      ) : (
        <Overview venues={rows} date={yearMonth!} />
      )}
    </>
  );
}

const GET_VENUE_RESERVATIONS = gql`
  query VenueReservationsQuery(
    $offset: Int!
    $limit: Int!
    $from: String
    $until: String
    $search: String
    $hasPaidReservations: HasPaidReservationsInput
  ) {
    venues(
      offset: $offset
      limit: $limit
      search: $search
      hasPaidReservations: $hasPaidReservations
    ) {
      total
      records {
        name
        id
        company {
          id
          bankAccount {
            id
          }
        }
        activities {
          id
          name
          priceTypes {
            id
            name
            unitType
            amount
            currencyCode
            contractedPrice
          }
          plan {
            id
            name
          }
          reservations(
            from: $from
            until: $until
            onlyPaidReservations: true
            onlyPrepaidReservations: true
          ) {
            id
            status
            datetime
            specialRequests
            commissionRate
            venuePaymentRate
            booking {
              id
              status
              familyName
              givenName
            }
            seatOption {
              price
            }
            items {
              id
              quantity
              costCurrencyCode
              contractedPrice
              priceType {
                contractedPrice
                currencyCode
                name
                id
              }
            }
            costs {
              commissionRate
              venuePaymentRate
              contractedAmount
              commission
              venuePaymentAmount
              currencyCode
              seatPrice
            }
          }
        }
      }
    }
  }
`;

const REPORT_VENUE_QUERY = gql`
  query ReportVenueQuery($from: DateTime!, $search: String) {
    getReportVenues(search: $search, from: $from) {
      records {
        url
        id
        venueId
        exportTime
        type
        totalAmount
        venue {
          id
          name
          nameFurigana
          email
          notificationEmails
          invoiceEmails
        }
      }
    }
  }
`;

const FETCH_COMPANY_VENUE = gql`
  query companiesToSendMail(
    $search: String, $from: DateTime!
  ) {
    companiesToSendMail(
      search:$search, from:$from
    ) {
      records {
        company_id
    company_name
    total_amount
    mail_status
    send_time
    manual_response
    }
    }
  }
`;

const SAVE_COMPANY_MUTATION = gql`
  mutation SaveCompanyMutation($input: BasicCompanyManualResponseInput!) {
    updateManualResponseStatus(input: $input) {
      success
      error
    }
  }
`;

const SEND_EMAIL = gql`
  query sendVenueNoticeEmails(
    $companyIds: [String!], $month: DateTime!
  ) {
    sendVenueNoticeEmails(
      companyIds:$companyIds, month:$month
    ) {
      success
    }
  }
`;

const PREVIEW_EMAIL = gql`
  query getListSendMailPreview(
    $companyIds: [String!], $month: DateTime!
  ) {
    getListSendMailPreview(
      companyIds:$companyIds, month:$month
    ) {
      success
      emailJobs {
      companyId
    attachments
    to
    subject
    emailType
    month
    companyName
    }
    }
  }
`;


const FETCH_FILE_ZIP_PDF_EXPORT = gql`
  query FetchFileZipPdfExport(
    $venueIds: [String!]!
    $month: DateTime!
    $type: Int!
  ) {
    fetchFileZipPdfExport(
      input: { venueIds: $venueIds, month: $month, type: $type }
    ) {
      filezip
      error
    }
  }
`;
const exportBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 100); // Tạo độ trễ nhỏ để thu hồi URL
};
