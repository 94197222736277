import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from "@mui/material";
import Button from "../../../../components/Button2";
import { useState, useCallback, useEffect } from "react";
import useStyles from "../styles";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import TextField from "../../../../components/TextField";
import { Activity, UnitType } from "../../../../types";
import { SeatOption } from "../../../Plans/Edit/Activities/ActivityForm/SeatOptionsForm";
import { PriceType } from "../../../Plans/Edit/Activities/ActivityForm/PriceTypesForm";
import { GET_BOOKING_ACTIVITY_RESERVATIONS } from "../ActivityReservations";
import { ItemType } from "../ActivityReservations/Items/Item";
import { useViewBookingContext } from "..";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import PriceTypeItem from "./Dialogs/PriceTypeItem";
import PriceTypeOldItem from "./Dialogs/PriceTypeOldItem";
import { useNotifications } from "../../../../components/Notification";

function checkQuantityPriceTypes(
  priceTypes: Array<{ quantity: number }>
): boolean {
  let isCheck = false;
  priceTypes.forEach((p) => {
    if (p.quantity > 0) {
      isCheck = true;
    }
  });
  return isCheck;
}

export default function ChangePlanButton(props: {
  bookingId: string;
  oldPlan: string;
}) {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Button
        style={{ marginLeft: 10 }}
        color="secondary"
        onClick={() => setDialogOpen(true)}
      >
        Change Plan
      </Button>
      <ChangeDateTimeDialog
        open={dialogOpen}
        closeDialog={() => setDialogOpen(false)}
        bookingId={props.bookingId}
        oldPlan={props.oldPlan}
      />
    </>
  );
}

function ChangeDateTimeDialog(props: {
  open: boolean;
  closeDialog: () => void;
  bookingId: string;
  oldPlan: string;
}) {
  const {
    setNewPlan,
    setActivityId,
    setSeatOption: setSeatOptionSelected,
    setPriceTypes: setPriceTypesSelected,
    setBookingPrice,
    productPrice,
  } = useViewBookingContext();
  const { showNotification } = useNotifications();
  const classes = useStyles();
  const [openDialogConfirm, setOpenDialogConfirm] = useState<boolean>(false);
  const [planId, setPlanId] = useState("");
  const [activity, setActivity] = useState("");
  const [seatOption, setSeatOption] = useState("");

  const [seatOptions, setSeatOptions] = useState<SeatOption[]>([]);
  const [priceTypes, setPriceTypes] = useState([]);
  const [priceTypesOld, setPriceTypesOld] = useState<ItemType[]>([]);

  const [getPlan, { data: plan }] = useLazyQuery(PLAN_BASIC_INFO_QUERY);
  const [getPlanActivity, { data: PlanActivity, loading }] = useLazyQuery(
    PLAN_ACTIVITIES_QUERY
  );

  const { data } = useQuery(GET_BOOKING_ACTIVITY_RESERVATIONS, {
    variables: { bookingId: props.bookingId },
  });

  const handleChangeActivity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const activityId = (event.target as HTMLInputElement).value;
    const activityItem = PlanActivity?.plan?.activities?.find(
      (a: Activity) => a.id === activityId
    );
    setActivity(activityId ?? "");
    setSeatOptions(activityItem?.seatOptions ?? []);
    setSeatOption(activityItem?.seatOptions[0].id ?? "");
    setPriceTypes(activityItem?.priceTypes ?? []);
  };

  const movePriceType = useCallback(
    (fromIndex: number, toIndex: number) => {
      const updatedPriceTypes = [...priceTypes];
      const [movedItem] = updatedPriceTypes.splice(fromIndex, 1);
      updatedPriceTypes.splice(toIndex, 0, movedItem);
      setPriceTypes(updatedPriceTypes);
    },
    [priceTypes]
  );

  const movePriceTypeOld = useCallback(
    (fromIndex: number, toIndex: number) => {
      const updatedPriceTypes = [...priceTypesOld];
      const [movedItem] = updatedPriceTypes.splice(fromIndex, 1);
      updatedPriceTypes.splice(toIndex, 0, movedItem);
      setPriceTypesOld(updatedPriceTypes);
    },
    [priceTypesOld]
  );

  const handleChange = () => {
    const priceTypesSelected = priceTypes.map(
      (priceType: PriceType, index: number) => {
        if (priceTypesOld?.[index]) {
          return {
            id: priceTypesOld[index].id,
            priceType: priceType.name,
            priceTypeId: priceType.id,
            quantity: priceTypesOld[index].quantity,
            unitType: priceType.unitType === UnitType.SET ? "セット" : "名",
            amount: priceType.amount,
          };
        }
        return {
          priceType: priceType.name,
          priceTypeId: priceType.id,
          quantity: 0,
          unitType: priceType.unitType === UnitType.SET ? "セット" : "名",
          amount: priceType.amount,
        };
      }
    );

    if (!checkQuantityPriceTypes(priceTypesSelected)) {
      return showNotification({
        message:
          "Please check the price type quantity there is no price with valid quantity.",
        severity: "error",
      });
    }
    const seatOptionSelected = seatOptions.find(
      (option: SeatOption) => option.id === seatOption
    );
    const newPrice = priceTypesSelected.reduce((init, p) => {
      if (p.amount && p.quantity) {
        return init + p.amount * p.quantity;
      }
      return init;
    }, 0);

    setNewPlan({
      id: plan?.plan?.id,
      name: plan?.plan?.name,
    });
    setSeatOptionSelected({
      id: seatOptionSelected?.id,
      title: seatOptionSelected?.title,
    });
    setActivityId(activity ?? null);
    setPriceTypesSelected(priceTypesSelected);
    setBookingPrice(newPrice + productPrice);
    props.closeDialog();
  };

  useEffect(() => {
    const activityReservations = data.bookingActivityReservations.records;
    setPriceTypesOld(activityReservations[0]?.items);
  }, [data]);

  return (
    <Dialog maxWidth="lg" open={props.open} onClose={props.closeDialog}>
      <DialogTitle>Change Plan</DialogTitle>
      <DialogContent style={{ maxWidth: "1000px", minWidth: "1000px" }}>
        <Box className={classes.search_box}>
          <TextField
            style={{ width: "100%", margin: "0 10px 0 0" }}
            label={"ID"}
            value={planId}
            onChange={(e: any) => setPlanId(e.target.value)}
          />
          <Button
            style={{ height: "56px" }}
            onClick={async () => {
              if (planId === props.oldPlan) {
                return showNotification({
                  message:
                    "The new plan must not overlap with the current plan.",
                  severity: "error",
                });
              }
              await getPlan({ variables: { id: planId } }).then((data: any) => {
                if (!data?.data?.plan) {
                  return showNotification({
                    message: "Failed to get plan " + planId,
                    severity: "error",
                  });
                }
              });
              await getPlanActivity({ variables: { id: planId } });
            }}
          >
            search
          </Button>
        </Box>
        {plan && (
          <Box style={{ marginTop: 16 }}>
            <h3 style={{ marginBottom: 0 }}>Plan info</h3>
            <TextField readOnly={true} value={plan?.plan?.id} />
            <TextField readOnly={true} value={plan?.plan?.name} />
            <h3>Activities info</h3>
            {loading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            ) : (
              <FormControl style={{ width: "100%" }}>
                <RadioGroup
                  defaultValue="female"
                  value={activity}
                  onChange={handleChangeActivity}
                >
                  {PlanActivity?.plan?.activities?.map((activity: Activity) => {
                    return (
                      <FormControlLabel
                        value={activity?.id as string}
                        control={<Radio />}
                        label={activity?.name}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            )}

            <h3 style={{ marginBottom: 0 }}>Seat Options info</h3>
            <FormControl style={{ width: "100%" }}>
              <RadioGroup
                defaultValue="female"
                value={seatOption}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSeatOption(e.target.value)
                }
              >
                {seatOptions?.map((option: SeatOption) => {
                  return (
                    <FormControlLabel
                      value={option?.id as string}
                      control={<Radio />}
                      label={option?.title}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
            {priceTypes && priceTypes.length > 0 && (
              <DndProvider backend={HTML5Backend}>
                <Box className={classes.activities_info_box}>
                  <Box style={{ width: "50%" }}>
                    <h3 style={{ marginBottom: 10 }}>
                      New Price Types{" "}
                      <span style={{ fontSize: "12px", fontWeight: 400 }}>
                        (You can drag and drop the price to the desired value)
                      </span>
                    </h3>
                    {priceTypes.map((type: PriceType, index: number) => {
                      return (
                        <PriceTypeItem
                          type={type}
                          index={index}
                          movePriceType={movePriceType}
                        />
                      );
                    })}
                  </Box>
                  <Box style={{ width: "50%" }}>
                    <h3 style={{ marginBottom: 20, paddingLeft: 22 }}>
                      Old Price Types
                      <span style={{ fontSize: "12px", fontWeight: 400 }}>
                        (You can drag and drop the price to the desired value)
                      </span>
                    </h3>
                    {priceTypesOld?.map((type: ItemType, index: number) => {
                      return (
                        <PriceTypeOldItem
                          index={index}
                          type={type}
                          movePriceType={movePriceTypeOld}
                          priceTypes={priceTypes}
                        />
                      );
                    })}
                  </Box>
                </Box>
              </DndProvider>
            )}
          </Box>
        )}
        <Dialog open={openDialogConfirm}>
          <DialogTitle>{`Edit plan?`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You are about to edit a field that usually shouldn't be changed.
              <br />
              Please consider whether this change is necessary before
              proceeding.
              <br />
              Contact the Development team in case of doubt.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleChange();
                setOpenDialogConfirm(false);
              }}
              style={{ fontWeight: 100 }}
            >
              Continue
            </Button>
            <Button
              style={{ fontWeight: 700 }}
              onClick={() => setOpenDialogConfirm(false)}
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            props.closeDialog();
          }}
        >
          Cancel
        </Button>
        <Button onClick={() => setOpenDialogConfirm(true)}>Change</Button>
      </DialogActions>
    </Dialog>
  );
}

const PLAN_BASIC_INFO_QUERY = gql`
  query PlanBasicInfoQuery($id: ID!) {
    plan(id: $id) {
      id
      name
    }
  }
`;

const PLAN_ACTIVITIES_QUERY = gql`
  query PlanActivityQuery($id: ID!) {
    plan(id: $id) {
      id
      activities {
        id
        name
        courseMenu
        coursePhotos {
          activityId
          id
          photoIndex
          src
        }
        detailsForVenue
        contract {
          commissionRate
          onSiteCommissionRate
        }
        priceTypes {
          id
          name
          unitType
          amount
          contractedPrice
          currencyCode
          minAttendees
          maxAttendees
          index
          active
        }
        venue {
          id
          name
        }
        offset {
          minutes
          hours
        }
        seatOptions {
          id
          title
          details
          minAttendees
          maxAttendees
          photos {
            src
          }
          active
          price
        }
        questionsByVenue
      }
    }
  }
`;
