import React, { useState, useEffect } from "react";
import { Grid, TextField, IconButton, Select, MenuItem } from "@mui/material";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useNotifications } from "../../../../components/Notification";
import PageSection from "../../../../components/PageSection";
import Button2 from "../../../../components/Button2";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import MediaUploader, { MediaType } from "../../../../components/MediaUploader";

// Define the types
type PaidItem = {
  id?: string;
  title?: string;
  body: string;
  imageUrl: null | string;
  name: string;
  price: number;
  status: number;
};

type PlanPaidItemInput = {
  planId: string;
  name: string;
  price: number;
  description: string;
  planQuestionId: string;
  status: number;
  imageUrl: string;
};

const initialPaidItem: PaidItem = {
  title: "",
  body: "",
  imageUrl: null,
  name: "",
  price: 0,
  status: 1,
};

// GraphQL query to fetch paid items for a specific plan
const GET_PLAN_PAID_ITEMS = gql`
  query getPlanPaidItems($planId: ID!) {
    getPlanPaidItems(planId: $planId) {
      id
      name
      price
      description
      planQuestionId
      status
      imageUrl
    }
  }
`;

// Define the mutation to create a paid item
const CREATE_PLAN_PAID_ITEM = gql`
  mutation createPlanPaidItem($input: PlanPaidItemInput!) {
    createPlanPaidItem(input: $input) {
      id
    }
  }
`;

// Define the mutation to update a paid item
const UPDATE_PLAN_PAID_ITEM = gql`
  mutation UpdatePlanPaidItem($id: ID!, $input: PlanPaidItemInput!) {
    updatePlanPaidItem(id: $id, input: $input) {
      id
      planId
      name
      price
      description
      planQuestionId
      status
      imageUrl
    }
  }
`;

// GraphQL mutation for deleting a paid item
const DELETE_PLAN_PAID_ITEM = gql`
  mutation DeletePlanPaidItem($id: ID!) {
    deletePlanPaidItem(id: $id) {
      success
    }
  }
`;

export default function PaidOptionForm() {
  const { id: planId } = useParams<{ id: string }>(); // Get the planId from URL
  const { showNotification } = useNotifications();
  const [paidItems, setPaidItems] = useState<PaidItem[]>([]);
  const [createPlanPaidItem] = useMutation(CREATE_PLAN_PAID_ITEM); // Use mutation hook for creating
  const [updatePlanPaidItem] = useMutation(UPDATE_PLAN_PAID_ITEM); // Use mutation hook for updating
  const [deletePlanPaidItem] = useMutation(DELETE_PLAN_PAID_ITEM);

  const { loading, error, data, refetch } = useQuery(GET_PLAN_PAID_ITEMS, {
    variables: { planId },
  });

  // Effect to update the form once data is loaded
  useEffect(() => {
    if (data && data.getPlanPaidItems) {
      const fetchedPaidItems = data.getPlanPaidItems.map((item: any) => ({
        id: item.id,
        name: item.name,
        price: item.price,
        body: item.description,
        imageUrl: item.imageUrl,
        status: item.status,  // Convert "SHOW"/"HIDE" to 1/0
      }));
      setPaidItems(fetchedPaidItems);
    }
  }, [data]);

  const handleInputChange = (index: number, field: keyof PaidItem, value: any) => {
    setPaidItems((prevPaidItems: any) =>
      prevPaidItems.map((paidItem: PaidItem, idx: number) =>
        idx === index ? { ...paidItem, [field]: value } : paidItem
      )
    );
  };

  const handleImageUpload = (index: number, file: string | null) => {
    handleInputChange(index, "imageUrl", file);
  };

  const handleAddMore = () => {
    setPaidItems([...paidItems, { ...initialPaidItem }]);
  };

  // Function to delete a paid item
  const handleDeletePaidItem = async (index: number) => {
    const item = paidItems[index];

    if (item.id) {
      try {
        // Call the delete mutation for the specific item id
        const response = await deletePlanPaidItem({
          variables: {
            id: item.id,
          },
        });

        if (response.data.deletePlanPaidItem.success) {
          // Remove the item from the UI if deletion was successful
          setPaidItems((prevPaidItems: any) => prevPaidItems.filter((_: any, idx: number) => idx !== index));
          showNotification({
            message: "Paid Item deleted successfully",
            severity: "success",
          });
          refetch();
        } else {
          showNotification({
            message: "Failed to delete the item.",
            severity: "error",
          });
        }
      } catch (error: any) {
        console.error("Error deleting the item:", error);
        showNotification({
          message: error.message,
          severity: "error",
        });
      }
    } else {
      setPaidItems((prevPaidItems: any) => prevPaidItems.filter((_: any, idx: number) => idx !== index));
    }
  };

  // Save function to call the appropriate mutation for each paid item
  const handleSave = async () => {
    try {
      for (const item of paidItems) {
        const input: PlanPaidItemInput = {
          planId: `${planId}`,
          name: item.name,
          price: item.price,
          description: item.body,
          planQuestionId: `${planId}` || "", // Assuming the planQuestionId is the id of the item
          status: item.status,
          imageUrl: item.imageUrl || "",
        };

        if (item.id) {
          // If the item has an ID, update it
          await updatePlanPaidItem({
            variables: {
              id: item.id,
              input,
            },
          });
        } else {
          // If the item does not have an ID, create it
          await createPlanPaidItem({
            variables: {
              input,
            },
          });
        }
      }
      showNotification({
        message: "Paid items saved successfully.",
        severity: "success",
      });
      refetch();
    } catch (err) {
      console.error("Error saving paid items:", err);
      showNotification({
        message: "Error saving paid items.",
        severity: "error",
      });
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <Grid container sx={{ marginTop: "20px" }}>
      <PageSection lg={12}>
        <Button2
          variant="contained"
          onClick={handleSave}  // Save action on button click
          sx={{ marginRight: '16px' }}
        >
          Save
        </Button2>
        <IconButton onClick={handleAddMore}>
          <AddIcon />
        </IconButton>
      </PageSection>

      {paidItems.map((paidItem: PaidItem, index: number) => (
        <PageSection lg={12} key={index}>
          <IconButton sx={{ marginBottom: '16px' }} onClick={() => handleDeletePaidItem(index)}>
            <DeleteIcon />
          </IconButton>
          <Grid container spacing={2}>
            <Grid item lg={8} sx={{
              padding: "16px",
              borderRadius: "8px",
              marginBottom: "8px",
              backgroundColor: "white",
            }}>
              <TextField
                label="Option Name *"
                fullWidth
                value={paidItem.name}
                onChange={(e) => handleInputChange(index, "name", e.target.value)}
                sx={{ marginBottom: "10px" }}
              />
              <TextField
                label="Price *"
                fullWidth
                value={paidItem.price}
                onChange={(e) => handleInputChange(index, "price", parseFloat(e.target.value))}
                sx={{ marginBottom: "10px" }}
              />
              <TextField
                label="Description *"
                fullWidth
                multiline
                rows={4}
                value={paidItem.body}
                onChange={(e) => handleInputChange(index, "body", e.target.value)}
                sx={{ marginBottom: "10px" }}
              />
              <Select
                fullWidth
                value={paidItem.status}  // Use numeric values for status
                onChange={(e) => handleInputChange(index, "status", parseInt(`${e.target.value}`))}
                displayEmpty
              >
                <MenuItem value={1}>Show</MenuItem>
                <MenuItem value={0}>Hide</MenuItem>
              </Select>
            </Grid>
            <Grid
              item
              lg={4}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "auto",
                height: "auto",
              }}
            >
              <MediaUploader
                media={paidItem.imageUrl ? paidItem.imageUrl : null}
                allowedTypes={[MediaType.Image]}
                height="200px"
                uploadPath={`plans/templatecard/${paidItem.id}`}
                onChangeFunction={(url: string | null) => handleImageUpload(index, url)}
              />
            </Grid>
          </Grid>
        </PageSection>
      ))}
    </Grid>
  );
}
